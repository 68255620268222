/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */
@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Regular.otf');
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'NotoSansKR';
}
